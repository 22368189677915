let ENV = {};

if (
    ["lorni.wtfapps.com.br", "dash.lorni.com.br", "dasa.lorni.dev.br", "livelo.lorni.dev.br"].includes(
        window.location.hostname
    )
) {
    let tenant = "prod";
    ENV = {
        TENANT: tenant,
        APP_NAME: "Lorni",
        APP_ENV: "prod",
        BASE_URL: "https://apilorni.wtfapps.com.br/api",
        BASE_URL_SOCKET: "https://apilorni.wtfapps.com.br/v1/hubs/chat",
        URL_AWS_BUCKET: "https://lorni-storage-prod.s3.amazonaws.com/",
        wsUrl: "https://live.wtfapps.dev.br/",
    };
}

if (
    ["lorni.wtfapps.dev.br", "dash.lorni.dev.br"].includes(
        window.location.hostname
    )
) {
    let tenant = "dev";
    ENV = {
        TENANT: tenant,
        APP_NAME: "Lorni",
        APP_ENV: "dev",
        BASE_URL: "https://apilorni.wtfapps.dev.br/api",
        BASE_URL_SOCKET: "https://apilorni.wtfapps.dev.br/v1/hubs/chat",
        URL_AWS_BUCKET: "https://lorni-storage-externo.s3.us-east-2.amazonaws.com/",
        wsUrl: "https://live.wtfapps.dev.br/",
    };
}

if (window.location.hostname === "localhost") {
    let tenant = "local";
    ENV = {
        TENANT: tenant,
        APP_NAME: "Lorni",
        APP_ENV: "local",
        BASE_URL: "https://apilorni.wtfapps.dev.br/api",
        BASE_URL_SOCKET: "https://apilorni.wtfapps.dev.br/v1/hubs/chat",
        BASE_URL_SOCKET_LIVE: "https://apilorni.wtfapps.dev.br/v1/hubs/liveClass",
        URL_AWS_BUCKET: "https://lorni-storage-externo.s3.us-east-2.amazonaws.com/",
        wsUrl: "https://live.wtfapps.dev.br/",
    };
}

if (window.location.hostname === "externo.lorni.dev.br") {
    let tenant = "prod";
    ENV = {
        TENANT: tenant,
        APP_NAME: "Lorni",
        APP_ENV: "prod",
        BASE_URL: "https://api-externo.lorni.dev.br/api",
        BASE_URL_SOCKET: "https://api-externo.lorni.dev.br/v1/hubs/chat",
        BASE_URL_SOCKET_LIVE: "https://api-externo.lorni.dev.br/v1/hubs/liveClass",
        URL_AWS_BUCKET: "https://lorni-storage-externo.s3.us-east-1.amazonaws.com/",
        wsUrl: "https://live.wtfapps.dev.br/",
    };
}

if (window.location.hostname === "demo-v1.lorni.dev.br") {
    let tenant = "demo";
    ENV = {
        TENANT: tenant,
        APP_NAME: "Lorni",
        APP_ENV: "demo",
        BASE_URL: "https://api-demo-v1.lorni.dev.br/api",
        BASE_URL_SOCKET: "https://api-demo-v1.lorni.dev.br/v1/hubs/chat",
        BASE_URL_SOCKET_LIVE: "https://api-demo-v1.lorni.dev.br/v1/hubs/liveClass",
        URL_AWS_BUCKET: "https://lorni-storage-demo-v1.s3.amazonaws.com/",
        wsUrl: "https://live.wtfapps.dev.br/",
    };
}


export {ENV};
